
pre {
  border-top-color: #ddd;
  border-radius: 3px;
  font-size: .75rem;
  overflow-x: auto;
  line-height: 1.3;
  padding: 1.5rem;
  background: #fff;
  color: #333;
  overflow-x: auto;
  border: 1px solid #eee;
  border-bottom-color: #ddd;
}

/*

XCode style (c) Angel Garcia <angelgarcia.mail@gmail.com>

*/

.hljs {
  display: block;
}

.hljs-comment,
.hljs-quote {
  color: #006a00;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
  color: #aa0d91;
}

.hljs-name {
  color: #008;
}

.hljs-variable,
.hljs-template-variable {
  color: #660;
}

.hljs-string {
  color: #c41a16;
}

.hljs-regexp,
.hljs-link {
  color: #080;
}

.hljs-title,
.hljs-tag,
.hljs-symbol,
.hljs-bullet,
.hljs-number,
.hljs-meta {
  color: #1c00cf;
}

.hljs-section,
.hljs-class .hljs-title,
.hljs-type,
.hljs-attr,
.hljs-built_in,
.hljs-builtin-name,
.hljs-params {
  color: #5c2699;
}

.hljs-attribute,
.hljs-subst {
  color: #000;
}

.hljs-formula {
  background-color: #eee;
  font-style: italic;
}

.hljs-addition {
  background-color: #baeeba;
}

.hljs-deletion {
  background-color: #ffc8bd;
}

.hljs-selector-id,
.hljs-selector-class {
  color: #9b703f;
}

.hljs-doctag,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}
